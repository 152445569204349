input[name="inputHeaderSearch"]:focus{
    border-color: inherit !important;
}
.hmb-links-container {
    display: none;
}
.hmb-links-container a {
    text-decoration: none;
    color: #000;
    -webkit-transition: ease 0.2s;
    -moz-transition: ease 0.2s;
    -ms-transition: ease 0.2s;
    -o-transition: ease 0.2s;
    transition: ease 0.2s;
    border-bottom:1px solid #dedede;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.hmb-links-container a:last-child {
    border-bottom:none;
}

.hmb-links-container a.view-all{
    border-top: 1px solid #dedede;
    margin-top: -6px;
    padding-top: 4px;
}

.hmb-link {
    margin-bottom: 6px;
    display: block;
    padding-bottom: 4px;
}

.hmb-highlight {
    background-color: #d8b545;
    padding: 0 10px;
    text-transform: uppercase;
}

#hmtReviews span.golden-star{
    color: #ff9f2f;
}

@media (max-width: 991px) {
    /*MOBILE-TABLET*/
    .header-logo {
        width: 40px;
    }

    #hmbLogo {
        display: inline-block;
        margin-left: 20px;
        margin-right: 15px;
        float: left;
        line-height: 35px;
    }

    .postHeader {
        margin-top: 90px;
    }

    #header-mobile {
        display: block;
        height: 150px;
        position: fixed;
        top: 0;
        width: 100%;
        z-index: 888;
        /*z-index: 1000000002 !important;*/ /* FOR TAWK */
        border-bottom: 1px solid #b2b2b2;
        background-color: #FFF;
    }

    .first-row {
        height: 40px;
        background-color: #232323;
        border-bottom: 1px solid #FFF;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        text-align: center;
        padding: 0 10px;
    }

    .first-row .login {
        margin-right: 5px;
        float: right;
        cursor: pointer;
    }

    .first-row .top-message {
        display: inline-block;
    }

    #header-mobile .first-row .login .glyphicon {
        font-size: 20px;
        vertical-align: sub;
    }

    .first-row .hmt-btn-txt {
        color: #FFF;
        font-size: 12px;
        line-height: 40px;
    }

    .first-row .login .hmt-btn-txt {
        font-size: 10px;
        font-weight: bold;
    }

    #mobileMenu {
        display: inline-block;
        text-align: left;
        white-space: nowrap;
        float: left;
    }

    #mobileMenu .glyphicon {
        color: #FFF;
        line-height: 40px;
    }

    .second-row {
        padding: 0 5px;
        height: 77px;
        border-bottom: 1px solid #b2b2b2
    }

    #mobileLogo {
        line-height: 77px;
    }

    #mobileCart {
        cursor: pointer;
        position: relative;
        display: inline-block;
    }

    .v-center {
        height: 27px;
        margin: 25px 0;
        text-align: center;
    }

    #header-mobile .glyphicon {
        font-size: 25px;
    }

    #header-mobile .glyphicon-search, .remove-search-text {
        margin: 0 15px 0 0;
        cursor: pointer;
    }

    #header-mobile .close-search {
        display: none;
        position: absolute;
        top: -5px;
        left: 30px;
        width: 18px;
        line-height: 18px;
        background-color: #cdcdcd;
        color: #000;
        font-weight: bold;
        text-align: center;
        border-radius: 10px;
    }

    #header-mobile .glyphicon-menu-hamburger {
        cursor: pointer;
    }

    .shopping-cart-count {
        position: absolute;
        top: -5px;
        right: -5px;
        color: white;
        background-color: #DD0000;
        font-size: 10px;
        font-weight: bold;
        border-radius: 10px;
        width: 15px;
        height: 15px;
        line-height: 15px;
        text-align: center;
    }

    #header-menu {
        visibility: hidden;
        overflow-x: hidden;
        overflow-y: hidden;
        width: 80%;
        height: 100%;
        position: fixed;
        top: 0;
        left: -800px;
        z-index: 1000;
        /*z-index: 1000000001 !important;*/ /* FOR TAWK */
        background-color: #FFF;
        -webkit-transition: 0.4s ease;
        -moz-transition: 0.4s ease;
        -ms-transition: 0.4s ease;
        -o-transition: 0.4s ease;
        transition: 0.4s ease;
    }

    #header-menu-close {
        display: inline-block;
        cursor: pointer;
        float: left;
    }

    #header-menu-close .glyphicon {
        color: #FFF;
        line-height: 40px;
    }

    .header-menu-open {
        visibility: visible !important;
        left: 0 !important;
    }

    #header-menu .mobile-menu-left {
        position: absolute;
        height: 100%;
        left: 0;
    }

    #header-menu .mobile-menu-right {
        position: absolute;
        height: 100%;
        right: 0;
        padding-bottom: 220px;
    }

    #header-menu .mobile-menu-right .container {
        padding: 0;
    }

    #header-menu .mobile-menu-right .hmb-links-container {
        padding: 5px 0 20px 0;
    }

    #header-menu .mobile-menu-right .hmb-links-container .row:last-child .btn-info {
        color: #000;
        background-color: #FFF;
        text-transform: none;
        font-weight: bold;
        padding: 0;
        margin-top: 0;
        text-align: left;
    }

    #header-menu .mobile-menu-bottom {
        position: absolute;
        bottom: 0;
        padding: 0;
        background-color: #FFF;
    }

    #header-menu .mobile-menu-bottom .line {
        border-top: 1px solid #b2b2b2;
        min-height: 35px;
        line-height: 35px;
        padding-left: 15px;
        padding-right: 15px;
    }

    #header-menu .mobile-menu-bottom .line:last-child {
        padding: 0;
    }

    #doofinder-container {
        top: 90px !important;
    }

    .header-search {
        position: relative;
        display: inline-block;
        top: 1px;
        width: 100%;
    }

    .header-search #mobileSearch {
        position: absolute;
        float: right;
        width: 100%;
    }

    #mobileSearch input {
        height: 60px !important;
        border: none !important;
        font-size: 15px;
        border-bottom: 1px solid #b2b2b2 !important;
        padding-left: 20px;
    }

    .header-search-old #mobileSearch input {
        padding-left: 70px;
    }

    #mobileSearch .addon-icon, #header-menu-mobile .mobile-menu-left .searcher .addon-icon {
        line-height: 60px;
        font-size: 25px;
        pointer-events: all;
        right: 5px;
    }

    .header-search-form-open{
        -webkit-transition: .4s ease;
        -moz-transition: .4s ease;
        -ms-transition: .4s ease;
        -o-transition: .4s ease;
        transition: .4s ease;
        background-color: #fff;
    }

    .header-search .search-dropdown {
        background-color: #fff;
        position: relative;
        float: left;
        text-align: center;
        width: 60px;
        height: 60px;
        line-height: 75px;
        border-right: 1px solid #b2b2b2;
        border-bottom: 1px solid #b2b2b2;
        cursor: default;

        -webkit-user-select: none; /* Safari */
        -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* IE10+/Edge */
        user-select: none; /* Standard */
    }

    .header-search .search-dropdown i {
        color: #000;
        padding: 8px;
        cursor: pointer;
        font-size: 20px;
    }

    .header-search .search-dropdown .search-dropdown-types {
        position: absolute;
        text-align: left;
        border-style: solid;
        border-width: 0 1px 1px 1px;
        border-color: #b2b2b2;
        top: 60px;
        left: -1px;
        min-width: 200px;
        max-width: 272px;
        background-color: #FFFFFF;
    }

    .header-search .search-dropdown .search-dropdown-types .dropdown-info {
        width: 100%;
        height: 32px;
        line-height: 32px;
        font-size: 0.8em;
        padding: 0 10px;
    }

    .header-search .search-dropdown .search-dropdown-types .dropdown-option {
        width: 100%;
        min-height: 32px;
        padding: 5px 10px;
        display: block;
        cursor: pointer;
    }

    .header-search .search-dropdown .search-dropdown-types .dropdown-option .option-text {
        line-height: 20px;
        color: #000;
    }

    .header-search .search-dropdown .search-dropdown-types .dropdown-option.selected {
        background-color: #808080;
    }

    .header-search .search-dropdown .search-dropdown-types .dropdown-option.selected .option-text {
        font-weight: bold;
    }

    #header-mobile .header-search #mobileSearchInputNew::placeholder,
    #header-mobile .header-search #mobileSearchInput::placeholder {
        font-size: 15px;
    }

    .hmt-btn {
        line-height: 40px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        cursor: pointer;
    }

    #header-menu-top .top-message {
        display: none;
    }

    .top-message {
        line-height: 40px;
        color: white;
    }

    .top-message .loyaltyMobile-item span {
        color: white;
        margin-left: 10px;
        line-height: 30px;
    }

    .top-message .loyaltyMobile-item span b {
        color: white;
    }

    .line.hmtClaimMobile img {
        vertical-align: sub;
        height: 18px;
    }

    .line > .glyphicon {
        color: #6b6b6b;
        font-size: 25px;
        margin-left: 5px;
    }

    .line span.golden-star {
        color: #ff9f2f;
        vertical-align: middle;
    }

    .line .hmt-btn {
        line-height: 35px;
    }

    .line span.hmt-btn-txt {
        color: #000;
        font-size: 15px;
        font-weight: bold;
        vertical-align: middle;
    }

    .line:last-child span.hmt-btn-txt {
        margin-left: 5px;
    }

    .mobile-menu-left .menu-parent-item {
        margin: 15px 0;
    }

    .mobile-menu-left .hmb-highlight.create-design {
        margin: 0 -15px;
        padding: 15px 0 15px 15px;
    }

    .mobile-menu-left .menu-parent-item .ul-effect {
        position: relative;
    }

    .mobile-menu-left .menu-parent-item .ul-effect::after {
        content: '';
        border-bottom: 3px solid;
        position: absolute;
        bottom: -6px;
        left: 50%;
        width: 0;
        -webkit-transition: 0.25s ease;
        -moz-transition: 0.25s ease;
        -ms-transition: 0.25s ease;
        -o-transition: 0.25s ease;
        transition: 0.25s ease;
    }

    .mobile-menu-left .menu-parent-item .ul-effect:hover::after {
        left: 0;
        width: 100%;
    }

    .mobile-menu-left .menu-parent-item .hmb-btn-txt {
        cursor: pointer;
    }

    .mobile-menu-right, .mobile-menu-left {
        overflow-y: scroll;
    }

    .hmb-links-container a {
        border-bottom: 0;
        margin-bottom: 5px;
    }

    #hmtLocales {
        background-color: #232323;
    }

    #hmtLocales > .hmt-btn {
        position: relative;
    }

    #hmtLocales > .hmt-btn.hmt-locale-shared {
        width: 50%;
        float: right;
    }

    #hmtLocales .locale-dropdown {
        display: none;
        z-index: 200;
    }

    .hmtMobileStackWrapper {
        display: table-cell;
        vertical-align: bottom;
        position: absolute;
        bottom: 0;
        width: 100%;
    }

    .hmtClaimMobile {
        background: white;
    }

    .hmtClaimMobile:not(:first-child) {
    }

    #hmtLocalesMobile > .hmt-btn {
        background: white;
        width: 100%;
    }

    #hmtLocalesMobile #hmtLanguageMobile {
        right: 0;
    }

    #hmtLocalesMobile #hmtCountryMobile {
        left: 0;
    }

    #hmtLocalesMobile #hmtCountryMobile > img {
        padding-left: 15px;
    }

    #hmtLocalesMobile > .hmt-btn.hmt-locale-shared {
        width: 50%;
        float: right;
    }

    #hmtLocalesMobile .hmt-btn-txt {
        color: black;
    }

    #hmtLocalesMobile .locale-dropdown {
        display: none;
        overflow-y: auto;
        max-height: 370px;
        padding: 0 15px;
    }

    #hmtLocalesMobile .locale-dropdown > .locale-select:first-child {
        border: none;
    }

    #hmtLocalesFooter {
        display: block;
        margin-top: 15px;
        border-top: 1px solid #232323;
        border-bottom: 1px solid #232323;
        background: black;
    }

    #hmtLocalesFooter > .hmt-btn {
        padding: 0 10px;
        line-height: 40px;
        display: inline-block;
        cursor: pointer;
        color: #FFF;
        background: black;
        border-bottom: none;
    }

    #hmtLocalesFooter > .hmt-btn.hmt-locale-shared {
        width: 50%;
        float: right;
    }

    #hmtLocalesFooter .locale-dropdown {
        display: none;
        background-color: black;
        left: -50%;
        width: 100%;
        max-height: 350px;
        overflow-y: auto;
        text-align: initial;
        color: white;
    }

    #hmtCountry > img {
        vertical-align: text-bottom;
    }

    .locale-dropdown > .locale-select {
        padding: 2px 18px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        border-top: 1px solid #6b6b6b;
        transition: 0.2s ease;
    }

    .locale-dropdown > .locale-select:hover {
        background-color: #5278ce;

    }

    .locale-dropdown > .locale-select a {
        text-decoration: none;
    }

    .locale-dropdown>.locale-select .locale-txt {
        color: #232323;
        font-size: 11px;
        font-weight: bold;
    }

    #hmtLocalesFooter .locale-dropdown>.locale-select .locale-txt {
        color:white;
    }

    .hmb-btn-txt {
        color: #232323;
        font-size: 12px;
        font-weight: bold;
    }

    .hmb-links-container .header-services-group:first-child {
        border-bottom: 1px solid #232323;
    }
    .hmb-links-container .header-services-group {
        padding: 10px 0;
    }

    .hmb-links-container {
        position: absolute;
        top: 150px;
        left: 0;
        width: 100%;
        z-index: 10;
        background-color: #FFF;
        border-bottom: 1px solid #b2b2b2;
        padding: 15px;
        overflow-y: auto;
    }

    .hmb-links-container>.container>.row {
        margin: 0;
    }

    .hmb-links-container * {
        font-size: 12px;
    }

    .hmb-btn-open>span::after {
        left: 0;
        width: 100%;
    }

    .header-services-group .hmb-pers-main a {
        font-weight: bold;
        border:none;
    }

    #breadcrumbDiv, .containerFluidWinstickerProduct {
        margin-top: 150px;
    }

   /*
    * New Search Mobile
    */

    #new-design-search {
        margin: 17px 0;
        z-index: 100;
    }

    #new-design-search #mobileSearch, #header-menu-mobile .mobile-menu-left .searcher .inner-addon {
        border: 2px solid #000;
    }

    #new-design-search .row {
        margin-right: -14px;
    }

    #new-design-search #mobileSearch .inner-addon, #header-menu-mobile .mobile-menu-left .searcher .inner-addon {
        position: relative;
    }

    #new-design-search #mobileSearch .inner-addon .glyphicon, #header-menu-mobile .mobile-menu-left .searcher .glyphicon {
        position: absolute;
    }

    #new-design-search #mobileSearch .remove-search-text, #header-menu-mobile .mobile-menu-left .searcher .remove-search-text {
        right: 30px;
        top: 6px;
        bottom: 2px;
        line-height: 20px;
        cursor: pointer;
        width: 25px;
        height: 25px;
        font-weight: bold !important;
    }

    #new-design-search #mobileSearch .i-circle, #header-menu-mobile .mobile-menu-left .searcher .i-circle {
        background: #cdcdcd;
        color: #000;
        border-radius: 50%;
        font-size: 20px;
        font-weight: 700;
        text-align: center;
    }

    #new-design-search #mobileSearch .executeSearchButton, #header-menu-mobile .mobile-menu-left .searcher .executeSearchButton {
        background-color: #fbbc04;
        color: #000000;
        right: 0;
        top: 0;
        bottom: 0;
        font-size: 30px;
        line-height: 35px;
        padding: 0 3px;
        pointer-events: all;
        cursor: pointer;
        border-left: 2px solid #000;
        font-weight: bold;
        padding-top: 2px;
    }

    #new-design-search #mobileSearch input[type='search'], #header-menu-mobile .mobile-menu-left .searcher input[type='search'] {
        height: 38px !important;
        padding: 4px 6px;
        width: 90%;
        border: 0 !important;
    }

    /*  **************************************  */
}

@media (max-width: 991px) {
    #breadcrumbDiv.remove-margin-top, .containerFluidWinstickerProduct.remove-margin-top {
        margin-top: 0px;
    }
}

/********* BEGIN TEMPORAL ************/
@media (min-width: 1024px) {
    /*DESKTOP*/
    #headCnt {
        height: 154px;
    }

    #newsletter-align {
        display: flex;
        align-items: center;
    }

}

#newsletter-modal .advantage-newsletter, .newsletter-home .advantage-newsletter {
    color: #000 !important;
}

.advantage-newsletter b, .subtitle-newsletter b {
    color: #000 !important;
}

.newsletter-banner .save-newsletter .privacy-form-info {
    color: #0a0b0c;
    padding-left: 15px;
}

@media (max-width: 1024px) and (min-width: 992px) {
    /*DESKTOP*/
    #headCnt {
        height: 154px;
    }
}
/********* END TEMPORAL ************/

@media (min-width: 992px) {
    /*DESKTOP*/
    /** RECOVER WHEN REMOVE TEMPORAL */
    /*#headCnt {
        height: 190px;
    }*/

    #header-menu-middle {
        position: relative;
        width: 100%;
        height: 80px;
        line-height: 80px;
        border-bottom: 1px solid #000;
        padding-bottom: 20px;
    }

    #header-menu-middle .header-search {
        margin-top: 20px;
        z-index: 100;
    }
    #header-menu-middle .header-search .search-dropdown {
        float: left;
        text-align: center;
        width: 40px;
        height: 40px;
        line-height: 40px;
        border-style: solid;
        border-width: 1px 0 1px 1px;
        border-color: #000;
        position: relative;
        cursor: default;

        -webkit-user-select: none; /* Safari */
        -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* IE10+/Edge */
        user-select: none; /* Standard */
    }

    .new-searcher-desktop .search-dropdown {
        background-color: #E7E7E7;
        width: 200px !important;
    }
    #header-menu-middle .header-search .search-dropdown i {
        color: #000;
        padding: 8px;
        cursor: pointer;
    }
    #header-menu-middle .header-search .search-dropdown .search-dropdown-types {
        position: absolute;
        text-align: left;
        background-color: #FFFFFF;
        border-style: solid;
        border-width: 0 1px 1px 1px;
        border-color: #000000;
        top: 39px;
        left: -1px;
        min-width: 200px;
        max-width: 272px;
        z-index: 555;
    }
    #header-menu-middle .header-search .search-dropdown .search-dropdown-types .dropdown-info {
        width: 100%;
        height: 32px;
        line-height: 32px;
        font-size: 0.8em;
        padding: 0 10px;
    }
    #header-menu-middle .header-search .search-dropdown .search-dropdown-types .dropdown-option {
        width: 100%;
        min-height: 32px;
        padding: 5px 10px;
        display: block;
        cursor: pointer;
    }
    #header-menu-middle .header-search .search-dropdown .search-dropdown-types .dropdown-option .option-text {
        line-height: 20px;
        color: #000;
    }
    #header-menu-middle .header-search .search-dropdown .search-dropdown-types .dropdown-option.selected {
        background-color: #808080;
    }
    #header-menu-middle .header-search .search-dropdown .search-dropdown-types .dropdown-option.selected .option-text {
        font-weight: bold;
    }
    #header-menu-middle .header-search .addon-icon {
        background-color: #fbbc04;
        color: #000;
        right: 1px;
        top: 1px;
        /*bottom: 1px;*/
        font-size: 32px;
        line-height: 40px;
        padding: 0 3px;
        pointer-events: all;
        height: 38px;
    }

    .old-searcher-desktop .desktopSearch {
        float: left;
        width: 80%;
    }

    .new-searcher-desktop .desktopSearch {
        float: left;
        width: calc(100% - 200px) !important;
    }

    #header-menu-middle .header-search .desktopSearch input[type='search'] {
        height: 40px !important;
    }

    #header-menu-middle .header-cart {

    }
    #header-menu-middle .header-cart .cart {
        float: right;
        cursor: pointer;
    }
    #header-menu-middle .header-cart .cart .label {
        color: #000;
        background-color: #FFF;
    }
    #header-menu-middle .header-cart .cart .glyphicon {
        position: relative;
        font-size: 2em;
        color: #000;
        top: 10px;
    }
    #header-menu-middle .header-cart .cart .glyphicon .count {
        position: absolute;
        top: 0px;
        left: 20px;
        text-align: center;
        padding-right: 2px;
        font-size: 10px;
        font-weight: bold;
        color: #FFF;
        background-color: #3399ff;
        border-radius: 8px;
        width: 16px;
        height: 15px;
        line-height: 15px;
    }


    #header-menu {
        border-bottom: 1px solid #000;
        position: fixed;
        width:100%;
        z-index:100;
        background:white;
    }
    #header-menu-top {
        background-color: #232323;
        height: 40px;
        top: 0;
        width: 100%;
        z-index: 100;
        text-align: center;
    }

    .hmt-review{
        text-align: left;
    }
    .hmt-btn {
        padding: 0 10px;
        line-height: 40px;
        display: inline-block;
        cursor: pointer;
        color: #FFF;
        text-align: left;
    }
    .hmt-btn > span.glyphicon {
        color: #6b6b6b;
        font-size: 20px;
        vertical-align: text-bottom;
    }
    .hmt-btn > span.hmt-btn-txt {
        color: #FFF;
        font-size: 10px;
        font-weight: bold;
        vertical-align: top;
    }
    .hmt-btn-right {
        float: right;
        border-left: 1px solid #6b6b6b;
    }
    .hmt-right {
        float: right;
    }
    .hmt-btn-left {
        /*border-right: 1px solid #6b6b6b;*/
        border-left: 1px solid #6b6b6b;
        float:left;
    }
    .hmtClaims {
        max-width: 550px;
        white-space: nowrap;
        overflow: hidden;
        max-height: 40px;
    }
    .hmtClaim {
        line-height: 35px;
        padding: 0 10px;
        display: inline-block;
        overflow: hidden;
    }
    #hmtLocales {
        display: initial;
    }
    #hmtLocales>.hmt-btn {
        position: relative;
    }
    #hmtLocales .locale-dropdown {
        display: none;
        position: absolute;
        top: 40px;
        background-color: #232323;
        left: -50%;
        width: 200%;
        max-height: 350px;
        overflow-y: auto;
        z-index:200;
    }
    #hmtCountry > img {
        vertical-align: text-bottom;
    }
    .locale-dropdown>.locale-select {
        padding: 2px 10px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        border-top: 1px solid #6b6b6b;
        transition: 0.2s ease;
    }
    .locale-dropdown>.locale-select:hover {
        background-color: #5278ce;
    }
    .locale-dropdown>.locale-select a {
        text-decoration: none;
    }
    .locale-dropdown>.locale-select .locale-txt {
        color: #FFF;
        font-size: 10px;
        font-weight: bold;
    }

    #header-menu-bottom {
        /*padding-top: 5px;
        padding-bottom: 5px;*/
        border-color: #000;
    }
    #header-menu-bottom .row {
        margin-right: 100px;
        margin-left: 100px;
        text-align: center;
    }
    #header-menu-bottom .ul-effect::after {
        border-bottom: 5px solid;
        bottom: -10px;
    }
    #header-menu-bottom .menu-parent-item {
        display: inline-block;
        margin-left: 15px;
        margin-right: 15px;
        width: unset;
    }

    #header-menu-bottom .menu-parent-item a {
        vertical-align: sub;
    }

    #header-menu-bottom .sticky-search-icon {
        float: right;
        position: fixed;
        top: 6px;
        right: 60px;
        cursor: pointer;
    }
    #header-menu-bottom .sticky-search-icon .mdi-action-search {
        font-size: 26px;
        color: #000;
    }
    #header-menu-bottom .sticky-cart-icon {
        float: right;
        position: fixed;
        top: 7px;
        right: 25px;
        cursor: pointer;
    }
    #header-menu-bottom .sticky-cart-icon .glyphicon {
        position: relative;
        font-size: 1.5em;
        color: #000;
    }
    #header-menu-bottom .sticky-cart-icon .glyphicon .count {
        position: absolute;
        top: -3px;
        left: 15px;
        text-align: center;
        font-size: 10px;
        font-weight: bold;
        color: #FFF;
        background-color: #3399ff;
        border-radius: 8px;
        width: 16px;
        height: 15px;
        line-height: 15px;
    }

    .hmb-btn {
        cursor: pointer;
        height: 28px;
        text-align:left;
        padding-left: 0;
    }
    .hmb-btn.hmb-btn-small {
        width: 13%;
    }
    .hmb-btn > span.hmb-btn-txt {
        display: inline-block;
        color: #000;
        font-weight: bold;
        font-size: 12px;
        text-align: center;
        vertical-align: sub;
    }
    .top-message{
        display:inline-block;
        line-height:40px;
    }
    .top-message .loyalty-item span{
        color: white;
        margin-left: 10px;
    }
    .top-message .loyalty-item span b{
        color: white;
    }
    .hmb-btn-open>span::after {
        left: 0;
        width: 100%;
    }

    #reviewsDropDown {
        display: none;
        padding: 0 10px;
        z-index: 200;
        position: absolute;
        top: 40px;
        left: 0;
        width: 500px;
        background-color: #FFF;
        border: 0;
        box-shadow: 0 1px 6px rgba(0,0,0,0.2),0 1px 6px rgba(0,0,0,.2);
    }
    #reviewsDropDown .hmt-review {
        padding: 10px 0;
        border-top: 1px solid #6b6b6b;
    }
    #reviewsDropDown .hmt-review:first-child {
        border-top: none;
    }
    #reviewsDropDown .hmt-review>.glyphicon {
        width: 4%;
        vertical-align: top;
        padding-top: 5px;
        font-size: 15px;
    }
    #reviewsDropDown .hmt-review>.hmt-review-txt {
        width: 91%;
        display: inline-block;
        font-size: 12px;
        color: #000;
        margin: 0;
    }
    #reviewsDropDown a.btn {
        float: right;
    }

    #userDropDown {
        display: none;
        padding: 10px;
        z-index: 200;
        position: absolute;
        top: 40px;
        right: 0;
        width: 600px;
        background-color: #FFF;
        border: 0;
        box-shadow: 0 1px 6px rgba(0,0,0,0.2),0 1px 6px rgba(0,0,0,.2);
    }
    .logged-dropdown {
        width: 250px!important;
    }

    .hmb-links-container {
        position: absolute;
        top: 154px;
        left: 0;
        width: 100%;
        z-index: 10;
        background-color: #FFF;
        border-bottom: 1px solid #b2b2b2;
        padding: 15px;
    }
    .hmb-links-container>.container>.row {
        margin: 0;
    }
    .hmb-links-container * {
        font-size: 13px;
    }
    .hmb-links-container a:hover {
        /*color: #5278ce;*/
        color: #2196f3;
    }
    .hmb-links-container .header-services-group {
        float: left;
        width: 60%;
        padding: 0 15px;
    }
    .hmb-links-container .header-services-group:last-child {
        padding-left: 50px;
        width: 40%;
    }
    .header-services-group .hmb-pers-main {
        cursor: pointer;
        margin-bottom:10px;
        background-color:#e4e4e4;
        border: 1px solid #c5c5c5;
        height: 120px;
        overflow: hidden;
    }
    .header-services-group .hmb-pers-main img {
        float:left;
        margin:25px 15px;
    }
    .header-services-group .hmb-pers-main a {
        font-size: 25px;
        font-weight:bold;
    }
    .header-services-group .hmb-pers-main .hmb-pers-main-title {
        margin:0;
        padding-top:20px;
    }
    .header-services-group .hmb-pers-main .hmb-pers-main-txt {
        padding-right:10px;
    }
    .header-services-group .hmb-pers-main:hover a{
        color: #2196f3;
    }
    .header-services-group .hmb-pers-more-title {
        font-weight: bold;
        color: #000;
        text-transform: uppercase;
        padding-top: 30px;
    }
    .header-services-group .hmb-pers-more {
        margin: 15px 0;
    }

    #header-mobile {
        display: none;
    }
    .header-search-form-mobile {
        display:none;
    }
    .afterMenu {
        height:95px;
    }

    /* Header Sticky */
    #header-menu.sticky #header-menu-top {
        display: none;
    }
    #header-menu.sticky #header-menu-middle {
        display: none;
    }
    #header-menu.sticky #header-menu-bottom .hmb-links-container {
        top: 39px;
    }
    #header-menu.sticky #header-menu-bottom .sticky-search-icon {
        display: block !important;
    }
    #header-menu.sticky #header-menu-bottom .sticky-cart-icon {
        display: block !important;
    }
}

@media (min-width: 991px) and (max-width: 1500px) {
    .hmtClaim:not(:first-child) {
        display:none;
    }
}

/* MARQUEE ELEMENT */
@keyframes marquee {
    0%   { text-indent: 430px }
    100% { text-indent: -485px }
}

@-webkit-keyframes marquee {
    0%   { text-indent: 430px }
    100% { text-indent: -485px }
}

.marquee {
    overflow: hidden;
    white-space: nowrap;
    animation: marquee 17s linear infinite;
    -webkit-animation: marquee 17s linear infinite;
}

.marquee:hover {
    -webkit-animation-play-state: paused;
    animation-play-state: paused;
}
/* END MARQUEE ELEMENT */

/*CHECKOUT HEADER*/
#checkout-header {
    position: fixed;
    top: 0;
    line-height: 40px;
    width: 100%;
    border-bottom: 1px solid #ddd;
    background-color: #fff;
    z-index: 100;
}
#post-checkout-header {
    height: 41px;
}
#checkout-header img {
    max-height: 25px;
}
#checkout-help-btn {
    float: right;
    height: 40px;
    cursor: pointer;
}
#checkout-help-btn > span {
    line-height: 40px;
    vertical-align: bottom;
}
#checkout-help-btn > span.glyphicon {
    font-size: 25px;
}
@media (min-width: 992px) {
    /*DESKTOP*/
    #checkout-help-btn {
        background-color: #008cd7;
        padding: 0 15px;
        margin-right: -15px;
    }
    #checkout-help-btn > span {
        color: #FFF;
    }
    .container-results-search {
        line-height: 18px !important;
        max-height: 78vh;
        height: 78vh;
        box-shadow: 0 0 2px #000;
        background-color: #fff !important;
    }
    .container-results-search .loader-search {
        left: 63%;
    }

    .container-results, .container-filters-desktop
    {
        overflow-y: auto;
        max-height: 76vh;
        height: 76vh;
        /* This is the magic bit for Firefox */
        scrollbar-width: none;
    }

    .container-results::-webkit-scrollbar, .container-filters-desktop::-webkit-scrollbar {
        /* This is the magic bit for WebKit */
        display: none;
    }

    .number-results-container {
        margin-top: 20px;
    }
    .number-results-container * {
        color: #000;
     }
}
@media (max-width: 991px) {
    /*MOBILE & TABLET*/
    #checkout-help-btn > span.glyphicon {
        color: #008cd7;
    }
    .container-results-search .loader-search
    {
        left: 50%;
    }
    .container-results
    {
        overflow-y: auto;
        overflow-x: hidden;
    }
    .header-services-group .hmb-pers-more-title {
        font-weight: bold;
        color: #000;
        text-transform: uppercase;
        padding-top: 10px;
    }
}

/* TOP MESSAGES SPECIFIC SIZES */
@media only screen and (min-width: 992px) and (max-width: 1099px) {
    /*MOBILE & TABLET*/
    .top-message {
        max-width: 490px;
        overflow: hidden;
    }
    .hmtClaims {
        display: none;
    }
}
@media only screen and (min-width: 1100px) and (max-width: 1199px) {
    /*MOBILE & TABLET*/
    .top-message {
        max-width: 390px;
        overflow: hidden;
    }
}
@media (min-width: 1200px) {
    /*MOBILE & TABLET*/
    .top-message {
        max-width: 490px;
        overflow: hidden;
    }
}
/*END CHECKOUT HEADER*/

/* NEW SEARCHER */

/*.searcher-container {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
}*/

.container-results-search {
    position: absolute;
    z-index: 100;
    overflow-y: auto;
}

#container-results-search .list-item {
    margin-top: 15px;
}

#container-results-search .labels {
    display: none;
}

.loader-show .container-products {
    opacity: 30%;
}

#container-paginator
{
    margin-top: 20px;
}

#pageSelector
{
    margin-left: 5px;
    padding: 6px 12px;
    background: none;
    border: 1px solid #ddd;
    border-radius: 4px;
    color: #337ab7;
}

.container-results-search .loader-search
{
    position: fixed;
}

#container-results-search
{
    height: 75vh;
}

.remove-search, .show-children-categories
{
    cursor: pointer;
}

.btn-show-filters, .btn-show-sort
{
    background-color: transparent;
    border: 1px solid;
    text-transform: uppercase;
    color: #000;
    padding: 15px 12px;
    text-align: center;
    cursor: pointer;
    width: 50%;
    float: left;
}

.no-left-padding {
    padding-left: 0 !important;
}

.filter-title {
    text-transform: uppercase;
    font-weight: 300;
}

.btn-show-filters span, .btn-show-sort span
{
    color: inherit;
    font-size: 20px !important;
    line-height: 0;
    vertical-align: sub;
    margin-left: 15px;
}

@media (max-width: 425px) {
    .btn-show-filters, .btn-show-sort {
        font-size: 10px;
    }
    .btn-show-filters span, .btn-show-sort span {
        font-size: 13px !important;
    }
}

.mobile-search-open
{
    background-color: rgba(255, 255, 255, 0.5);
    height: 100vh;
}
.mobile-search-open.full {
    background-color: rgba(255, 255, 255, 1);
}

.container-filters-buttons,
.container-filters-results {
    position: fixed;
    bottom: 0px;
    width: 100%;
    padding-top: 10px;
    background-color: #fff;
}
.container-filters-results {
    padding-top: 15px;
}

.panel-filter-new-searcher, #container-last-searches .panel, #container-trending-searches .panel
{
    box-shadow: none;
    margin: 0 !important;
}

.new-searcher-filter
{
    border-bottom: 1px solid;
    margin-top: 6px;
    margin-bottom: 7px;
}
.new-searcher-filter.no-border {
    border-bottom: none;
}
.panel-icon
{
    font-size: 15px !important;
    position: absolute;
    line-height: 50px;
    right: 0;
    top: 1px;
    display: inline-block;
    font-style: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.panel-filter-new-searcher .list-filter-title
{
    margin-top: 10px;
}

.container-filters-results span
{
    font-size: 3vw !important;
}

.container-filters-results button
{
    line-height: 1.5;
    font-size: 3vw;
    padding: 8px 0px !important;
}

.btn-show-results, .btn-show-results span
{
    color: white !important;
    font-weight: bold;
}

.last-search-item label, .last-search-item span, .trending-searches-item label
{
    margin-top: 7px !important;
    margin-bottom: 7px !important;
    font-weight: 100;
}

.trending-searches-item:hover, .last-search-item:hover {
    font-weight: bold !important;
    text-shadow: 1px 0 0 #000;
}

.last-search-item *
{
    cursor: pointer;
}

#container-last-searches, #container-trending-searches {
    overflow-y: auto;
    overflow-x: hidden;
}

#btn-clear-last-searches {
    padding: 15px 0;
    font-weight: bold;
    color: #000;
    text-decoration: none;
}

#btn-clear-last-searches:hover {
    text-decoration: none;
}

#desktopNewSearcher input {
    margin-top: 20px;
    height: 60px !important;
    border: none !important;
    /*font-size: 20px;*/
    border-bottom: 1px solid #000000 !important;
    padding-left: 20px;
    font-size: 1.7vw;
}

#desktopNewSearcher .executeSearchButton, #desktopNewSearcher .remove-search-text {
    font-size: 1.7vw;
    margin-top: 15px;
    cursor: pointer;
    pointer-events: all;
}

#desktopNewSearcher input::placeholder {
    color: #000000;
}

#header-menu-middle .container-products
{
    margin-top: 20px;
}

/*#container-trending-searches
{
    margin-top: 20px;
}*/

#container-trending-searches label
{
    cursor: pointer;
    font-weight: 100;
}

@media (min-width: 992px) {
    .container-products .list-product:not(.list-product-small) .labels span {
        font-size: 10px !important;
    }
}

.list-item .title {
    line-height: 1em !important;
    font-size: 12px;
    width: 60% !important;
    height: 3em !important;
}

.list-item .price {
    width: 40% !important;
}

#panel-desktop-filters .btnClearFilter {
    padding: 8px 0 !important;
}

#panel-desktop-filters .btnClearFilter span {
    font-size: 13px !important;
}

/** Classes for Test A/B */
.mobileSearchButtonLeft {
    position: absolute;
    right: 16%;
}

.new-searcher-desktop .search-dropdown-product-type-active {
    color: #000000;
    font-weight: normal;
}

.new-searcher-desktop .search-dropdown-product-type-active.small {
    font-size: 10px;
}

.new-searcher-desktop .search-dropdown i {
    font-size: 10px;
}

.new-searcher-desktop .search-dropdown *, .new-searcher-desktop .search-dropdown {
    cursor: pointer !important;
}

.new-searcher-desktop:hover .search-dropdown, .new-searcher-desktop:hover input {
    border-color: #fbbc04 !important;
}

.new-searcher-desktop .dropdown-option:hover, .new-searcher-desktop .dropdown-option.selected {
    background-color: #E7E7E7 !important;
}

.new-searcher-desktop .dropdown-option.selected * {
    font-weight: bold !important;
}

.new-searcher-desktop .dropdown-option:hover * {
    text-shadow: 1px 0 0 #000;
}

.load-more-products {
    color: black !important;
    font-size: 15px;
    background-color: #75BAE8 !important;
}

.new-searcher-desktop .remove-search-text {
    background: #cdcdcd !important;
    border-radius: 50% !important;
    margin: 7px 50px 0 0 !important;
    font-size: 25px !important;
    font-weight: 700 !important;
    line-height: 16px !important;
    cursor: pointer !important;
    height: 25px!important;
    width: 25px!important;
    padding-top: 1px !important;
    text-align: center!important;
}

@media (max-width: 320px) {
    .header-logo {
        width: 33px;
    }
}

.search-suggestions {
    background-color: #FFF;
    /*padding: 2px 0;*/
    position: absolute;
    width: 100%;
}

#new-design-search .search-suggestions .search-suggestion:nth-child(n+5) {
    display: none;
}
#hmtSearch .search-suggestions .search-suggestion:nth-child(n+8) {
    display: none;
}

.search-suggestions .search-suggestion {
    line-height: initial;
    margin: 0;
    padding: 5px 0;
    color: #000;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
}
.search-suggestions .search-suggestion:hover, .search-suggestions .search-suggestion:active {
    background-color: #EEEEEE;
}
.search-suggestions .search-suggestion.user-search {
    color: #4555b7;
}
.search-suggestions .search-suggestion * {
    color: inherit;
}
.search-suggestions .search-suggestion.user-search * {
    color: inherit;
}

.search-suggestions .search-suggestion .remove-suggestion {
    visibility: hidden;
    color: #000;
    padding: 0 10px 0 5px;
}
.search-suggestions .search-suggestion.user-search .remove-suggestion {
    visibility: initial;
    cursor: pointer;
    color: #adb5bd !important;
}

.search-suggestions .search-suggestion .text-suggestion {
    font-weight: bold;
}

#header-menu-mobile .mobile-menu-left .top {
    background-color: #232323 !important;
}

#header-menu-mobile .mobile-menu-left .reviews * {
    color: #d8b545 !important;
}

#header-menu-mobile .mobile-menu-left .country-selector span , #header-menu-mobile .mobile-menu-left .login .txt {
    color: #fff;
    font-size: 10px;
    font-weight: 700;
}

#header-menu-mobile .mobile-menu-left .login .glyphicon {
    font-size: 20px;
    vertical-align: middle;
}

#header-menu-mobile .mobile-menu-left .reviews, #header-menu-mobile .mobile-menu-left .country-selector, #header-menu-mobile .mobile-menu-left .login {
    padding: 0.5rem 0 0.75rem !important;
    text-align: center !important;
    border-right: 1px solid #6c757d !important;
}

#header-menu-mobile .mobile-menu-left .close-left-menu {
    padding: 0.5rem 0 0.75rem !important;
    text-align: center !important;
}

#header-menu-mobile .mobile-menu-left .close-left-menu .glyphicon {
    color: #fff;
    font-weight: 700;
    vertical-align: middle;
}

#header-menu-mobile .mobile-menu-left .searcher {
    padding: 2rem 0 !important;
}

#header-menu-mobile .mobile-menu-left .searcher .search-suggestions .search-suggestion:nth-child(n+5) {
    display: none;
}

#header-menu-mobile .mobile-menu-left .home {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
    background-color: white !important;
}

#header-menu-mobile .mobile-menu-left .home a {
    text-decoration: none;
}

#header-menu-mobile .mobile-menu-left .home span {
    font-weight: bold;
    font-size: 18px;
    color: black;
    padding-left: 5px;
}

#header-menu-mobile .mobile-menu-left .personalized-link {
    margin: 0 !important;
    padding: 10px 15px !important;
}

#header-menu-mobile .mobile-menu-left .personalized-link a, #header-menu-mobile .mobile-menu-left .create-design span {
    color: black;
}

#header-menu-mobile .mobile-menu-left .create-design span, #header-menu-mobile .mobile-menu-left .view-all a {
    font-weight: bold;
}

#header-menu-mobile .mobile-menu-left .our-products {
    background-color: #232323;
}

#header-menu-mobile .mobile-menu-left .our-products span {
    font-weight: bold;
    color: white !important;
    text-transform: uppercase;
}

#header-menu-mobile .mobile-menu-left .our-products-link {
    padding: 10px 15px !important;
}

#header-menu-mobile .mobile-menu-left .our-products-link span {
    color: black;
}

#header-menu-mobile .mobile-menu-left .open-main-menu {
    background-color: #232323;
    padding: 10px 15px !important;
}

#header-menu-mobile .mobile-menu-left .category-link {
    padding: 10px 15px !important;
}

#header-menu-mobile .mobile-menu-left .open-main-menu span {
    color: white;
    font-weight: bold;
}

#header-menu-mobile .mobile-menu-left .open-main-menu .glyphicon {
    color: #000;
    background-color: white;
    line-height: 1;
    border-radius: 50%;
    padding: 4px;
    font-size: 13px;
}

#header-menu-mobile .mobile-menu-left .open-main-menu .glyphicon::before {
    content: "\E114" !important;
}

#header-menu-mobile .mobile-menu-left .category-link a {
    color: black;
    text-decoration: none;
}

#header-menu-mobile .mobile-menu-left .all-list-link a {
    font-weight: bold;
}

#header-menu-mobile .mobile-menu-left .bottom {
    background-color: #232323;
    padding-top: 5px !important;
    padding-bottom: 5px !important;
    position: absolute;
    width: 100%;
    bottom: 0;
    height: 40px;
}

#header-menu-mobile .mobile-menu-left .bottom span {
    color: white;
    font-size: 12px !important;
}

.text-gold {
    color: #d8b545 !important;
    font-weight: bold;
}

#header-menu-mobile .mobile-menu-left .bottom i {
    font-size: 20px;
    display: inline-block;
    vertical-align: middle;
    font-weight: normal !important;
}

#header-menu-mobile .mobile-menu-left .middle {
    overflow-y: auto;
}

#header-menu-mobile .mobile-menu-left .country-selector {
    position: static !important;
}

#header-menu-mobile .mobile-menu-left .country-selector .locale-select, #header-menu-mobile .mobile-menu-left .country-selector .locale-select-dropdown {
    padding: 10px 15px;
    border-bottom: 1px solid white;
    text-align: left;
}

#header-menu-mobile .mobile-menu-left .country-selector .selector {
    width: 100%;
    position: absolute;
    background-color: #232323;
    z-index: 1;
    left: 0;
    height: 50%;

}

#header-menu-mobile .mobile-menu-left .country-selector .selector .content {
    height: 100%;
    display: flex;
    flex-direction: column;
}

#header-menu-mobile .mobile-menu-left .country-selector .selector .locale-select:last-child, #header-menu-mobile .mobile-menu-left .country-selector .locale-select-dropdown:last-child {
    border: 0 !important;
}

#header-menu-mobile .mobile-menu-left .country-selector .selector .close-selector {
    background-color: #232323;
    box-shadow: 0 -10px 40px rgb(255 255 255 / 25%);
    padding: 10px 0;
}

#header-menu-mobile .mobile-menu-left .country-selector .languages {
    display: flex;
    padding: 15px 0;
    border-bottom: 1px solid white;
    flex: auto;
}

#header-menu-mobile .mobile-menu-left .country-selector .locale-select-dropdown .languages {
    border-bottom: 0;
    padding: 10px 0 0;
}

#header-menu-mobile .mobile-menu-left .country-selector .websites {
    flex: auto;
    overflow: auto;
}

#header-menu-mobile .mobile-menu-left .country-selector .languages .language {
    color: white;
    font-size: 10px;
    font-weight: bold;
    padding: 0 15px;
}

#header-menu-mobile .mobile-menu-left .country-selector .languages .language.selected {
    color: #d8b545;
}

#header-menu-mobile .mobile-menu-left .country-selector .selector .close-selector .glyphicon {
    font-size: 25px !important;
    font-weight: lighter !important;
}

#header-menu-mobile .mobile-menu-left .searcher .search-suggestions {
    z-index: 1;
}


#header-menu-mobile .mobile-menu-left .personalized-blocks .close-personalized-blocks {
    padding: 5px 15px;
}

#header-menu-mobile .mobile-menu-left .personalized-blocks .close-personalized-blocks * {
    color: white;
    font-weight: bold;
}

#header-menu-mobile .mobile-menu-left .personalized-blocks .personalized-block-container {
    padding-top: 15px;
}

#header-menu-mobile .mobile-menu-left .personalized-blocks .personalized-block {
    background-color: #E4E4E4;
    padding: 15px;
}

#header-menu-mobile .mobile-menu-left .personalized-blocks .personalized-block .row {
    display: flex;
    align-items: center;
}

#header-menu-mobile .mobile-menu-left .personalized-blocks .personalized-block a {
    color: black;
    font-weight: bold;
    font-size: 13px;
    display: block;
    padding-bottom: 5px;
}

#header-menu-mobile .mobile-menu-left .personalized-blocks .personalized-block span {
    color: black;
    font-size: 10px;
    display: block;
}

#header-menu-mobile .mobile-menu-left .personalized-blocks .other-services {
    padding-top: 15px;
}

#header-menu-mobile .mobile-menu-left .personalized-blocks .other-services span {
    font-size: 15px;
    font-weight: bold;
    color: black;
    text-transform: uppercase;
}

#header-menu-mobile .mobile-menu-left .personalized-blocks .view-all-services a {
    font-weight: bold;
}

#hmb-links-mobile .hmb-links-Personalized-Mobile .personalized-mobile-link {
    display: block;
    padding-bottom: 5px;
}

#header-menu-mobile .mobile-menu-left .image-claims {
    padding: 5px 15px;
}

#header-menu-mobile .mobile-menu-left .close-personalized-links .glyphicon, #header-menu-mobile .mobile-menu-left .open-personalized-links .glyphicon {
    color: #d8b545;
    background-color: white;
    line-height: 1;
    border-radius: 50%;
    padding: 4px;
    font-size: 13px;
}

#header-menu-mobile .mobile-menu-left .close-personalized-links .glyphicon:before {
    content: "\E114";
}

#header-menu-mobile .mobile-menu-left .open-personalized-links .glyphicon:before {
    content: "\E113";
}

#header-menu-mobile .mobile-menu-left .our-products, #header-menu-mobile .mobile-menu-left .create-design {
    padding: 15px !important;
}

#hmb-links-mobile .close-categories {
    padding-bottom: 20px;
}

.logged-user {
    color: #4caf50 !important;
}

.loyaltyNewsletter {
    visibility:visible;
    opacity:1;
    background-color: #ecd7c1;
    text-align: center;
    padding: 9px 0 5px 0;
    margin: 0;
    transition:visibility 0.5s linear,opacity 0.5s linear; /*, height 1.3s linear;*/
}
@media (max-width: 991px) {
    .loyalty-news-text {
        text-align: left;
    }
}
.loyaltyNewsletter * {
    font-size: 12px;
}
.notVisibleNewsletter {
    visibility: hidden !important;
    opacity: 0 !important;
}
.hiddenNewsletter {
    height: 0;
    margin: 0;
    padding: 0;
}
.hiddenNewsletter div {
    height: 0;
    margin: 0;
    padding: 0;
}

.newsletter-modal .font-size-12 {
    font-size: 12px !important;
}
.newsletter-modal .font-size-12 * {
    font-size: 12px !important;
}

.newsletter-modal .tvi-logo {
    color: black;
    font-size: 4em;
}

.new-header #breadcrumbDiv {
    margin: 0;
}

.new-header #headCnt {
    height: 140px;
    position: sticky;
    z-index: 2;
    top: 0;
    -webkit-transition: top 0.5s;
    -moz-transition: top 0.5s;
    -ms-transition: top 0.5s;
    -o-transition: top 0.5s;
    transition: top 0.5s;
}

.new-header #headCnt.header-min {
    top: -55px;
}

.new-header #header-messages:not(.closed-messages) ~ #headCnt {
    height: 190px !important;
    top: -50px;
}

.new-header #header-messages:not(.closed-messages) ~ #headCnt.header-min {
    top: -105px;
}

.new-header #header-menu {
    height: 140px;
    padding: 0;
    position: initial;
    visibility: visible;
    width: 100%;
    border: none;
    overflow-x: inherit;
    overflow-y: inherit;
}

.new-header .navigation-bar {
    display: flex;
    align-items: center;
    height: 100%;
}

.new-header .open-left-menu {
    cursor: pointer;
    padding-right: 10px;
    display: flex;
}

.new-header #header-menu .open-left-menu span {
    vertical-align: middle;
    color: black;
}

.new-header .logo {
    width: 120px;
    max-height: 100%;
    padding: 0 10px;
}

.new-header .logo img {
    width: 100%;
    max-height: 100%;
}

.new-header #header-menu .searcher {
    flex: 1 0 0;
    padding: 10px;
    background-color: #E1E0E0;
}

.new-header #header-menu .searcher input, .new-header #header-menu .searcher .input-group {
    height: 50px !important;
    z-index: 5;
}

.new-header #header-menu .searcher input {
    border-bottom-left-radius: 50px;
    border-top-left-radius: 50px;
    padding-left: 25px;
    background-color: #FFF;
    border: 1px solid #9D9D9D !important;
    border-right: none !important;
    font-size: 13px !important;
}

.new-header #header-menu .searcher input::placeholder {
    color: black;
}

.new-header #header-menu .searcher .executeSearchButton {
    border-bottom-right-radius: 50px;
    border-top-right-radius: 50px;
    padding-right: 25px;
    background-color: #FFF;
    top: 0;
    cursor: pointer;
    color: black;
    border: 1px solid #9D9D9D !important;
    border-left: none !important;
    display: table-cell !important;
    font-size: 20px;
}

.new-header #mobile-searcher .executeSearchButton {
    font-size: 20px;
    display: table-cell !important;
}

.new-header .user {
    padding: 0 10px;
    position: relative;
}

.new-header .user button {
    padding: 0;
    margin: 0;
    text-transform: none;
    box-shadow: none !important;
}

.new-header .user .hmt-btn-txt {
    color: black;
    vertical-align: middle;
}

.new-header #header-menu .cart {
    padding-left: 10px;
    position: relative;
}

.new-header #header-menu .cart .shopping-cart-number-of-items {
    background-color: #D6236F;
    position: absolute;
    border-radius: 50%;
    padding: 0 4px;
    transform: translate(-50%, -50%);
    left: 100%;
    top: 0;
    font-size: 10px;
    color: white;
    font-weight: bold;
}

.new-header #header-menu .cart i {
    color: black;
    vertical-align: middle;
    cursor: pointer;
}

#header-messages {
    height: 50px;
    display: flex;
    align-items: center;
    background-color: black;
    justify-content: center;
    position: relative;
    z-index: 3;
    min-height: 0;
    overflow: hidden;
}

#header-messages .loyalty-item, #header-messages .loyalty-item * {
    font-size: 11px;
}

#header-messages.closed-messages, #header-messages.closed-messages .close-container {
    height: 0;
}

@media (min-width: 992px) {
    #header-messages .loyalty-item, #header-messages .loyalty-item * {
        font-size: 12px;
    }

    #header-messages {
        height: 40px;
        white-space: nowrap;
        overflow: hidden;
    }

    .new-header #header-messages:not(.closed-messages) ~ #headCnt {
        height: 188px !important;
    }

    .new-header #headCnt {
        height: 148px;
        top: -40px;
    }

    .new-header #header-menu {
        height: 148px;
    }

    .new-header #header-messages.closed-messages ~ #headCnt {
        top: 0 !important;
    }

    .new-header #header-menu .glyphicon, .new-header #header-menu i {
        font-size: 24px;
    }
    .new-header #header-menu .open-left-menu i {
        font-size: 16px;
    }

    .new-header #header-menu .searcher input {
        font-size: 15px !important;
    }

    .new-header #header-menu {
        /*padding: 0 35px;*/
        position:relative;
    }

    .new-header .logo {
        width: 200px;
        height: 50px;
        padding: 0;
    }

    .new-header .open-left-menu {
        display: block;
    }

    .new-header .user {
        width: 160px;
        text-align: right;
    }

    .new-header .user i {
        vertical-align: middle;
    }

    .new-header .searcher .search-suggestions .text-suggestion, .new-header .searcher .search-suggestions .text-suggestion * {
        font-size: 15px;
    }
    .dh-grid {
        margin: auto;
        max-width: 1920px;
        display: block!important;
    }
    .dh-container {
        display: flex;
        width: 100%;
        padding: 0 12px;
        margin: 0 auto;
    }
    .dh-content {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -12px;
        align-items: center !important;
        width: 100%;
    }
    .dh-left {
        flex: 0 0 auto;
        width: 25%;
        max-width: 100%;
        padding-left: 48px;
        padding-right: 12px;
    }
    .dh-left img {
        max-height: 100%;
        width: 100%;
        max-width: 100%;
        height: auto;
    }
    .dh-right {
        flex: 1 0 0%;
        width: 100%;
        max-width: 100%;
        padding: 0 12px;
    }
    .dh-top {
        padding-left: 110px;
        background: linear-gradient(45deg, #FFF, #FFF 80px, #E1E0E0 80px, #E1E0E0);
        height: 110px;
        display: flex;
        flex-wrap: wrap;
        margin: 0 -12px;
        align-content: center!important;
    }
    .dh-top .searcher {
        height: auto;
        width: 100%;
        margin: 0;
        padding: 0 8px 0 12px!important;
        flex: 1 0 0%;
    }
    .dh-top .user {
        width: 160px;
        padding: 0 8px!important;
        flex: 0 0 auto;
    }
    .dh-top .user .btn {
        padding: 0!important;
        margin: auto;
        align-items: center;
        justify-content: space-between;
        flex-direction: column;
        height: 100%;
        display: flex;
    }
    .dh-top .cart {
        flex: 0 0 auto;
        width: auto;
        padding: 0 12px 0 8px!important;
    }
    .dh-top .dh-cart .cart {
        padding: 0!important;
        align-items: center;
        justify-content: space-between;
        flex-direction: column;
        height: 100%;
        display: flex;
        position: relative;
        margin: auto;
    }
    .dh-bottom {
        padding: 8px 0 8px 122px;
        display: flex;
        margin: 0 -12px;
    }
    .dh-bottom .om-menu {
        margin: 0 8px;
        padding-right: 8px;
        align-self: flex-end!important;
    }
    .dh-bottom .om-menu .open-left-menu {
        display: flex;
        padding: 0;
        margin: auto;
        line-height: 1;
    }
    .dh-bottom .om-menu .open-left-menu .dh-text {
        line-height: 1.25;
        padding-left: 8px;
    }
    .dh-bottom .om-menu .open-left-menu .tvi-bars {
        line-height: 1.25;
    }
    .dh-bottom .dh-om-menu-links {
        flex: 1 1 auto!important;
        line-height: 1.25;
        align-self: center;
    }
    .dh-bottom .om-link {
        padding: 0 8px;
        margin: 0 8px;
    }
    .dh-bg-aux {
        background: linear-gradient(90deg, #FFF, #FFF 50%, #E1E0E0 50%, #E1E0E0);
        z-index: -1;
        height: 110px;
        width: 100%;
        position: absolute;
        top: 0;
    }
    .dh-row {
        padding-left: 110px;
    }
    .dh-text {
        font-size: 15px;
        font-weight: bold;
        text-decoration: none!important;
        color: #000!important;
    }
    .dh-top .dh-text {
        font-size: 12px;
    }
}

#header-messages .loyalty-item span, #header-messages .loyalty-item span * {
    color: white;
}

#header-messages .close-container {
    position: absolute;
    right: 0;
    background: #000;
}

#header-messages .close-messages {
    padding: 0 20px;
    box-shadow: none;
}

#header-messages .close-messages span {
    color: white;
    font-size: 12px;
}

.new-header .navigation-bar .glyphicon, .new-header .navigation-bar i {
    font-size: 20px;
    color: black;
    cursor: pointer;
    vertical-align: middle;
}

.open-left-menu i{
    color: #D6236F !important;
}

#header-navigation-mobile {
    height: 70px;
    z-index: 3;
    padding: 0 20px;
}

#header-navigation-mobile .left {
    flex: 1 0 0;
    display: flex;
    align-items: center;
}

#header-navigation-mobile .right {
    display: flex;
    align-items: center;
}

.new-header .searcher .search-suggestions {
    padding-top: 40px;
    padding-bottom: 15px;
    top: 50%;
    left: 0;
    box-shadow: 0 9px 8px rgba(0, 0, 0, 0.15);
    display: none;
    z-index: 1;
}

.new-header .searcher .search-form {
    height: 100%;
    position: relative;
}

.new-header .searcher .search-suggestion {
    padding: 8px 5px;
    display: flex;
    align-items: center;
}

.new-header .searcher .search-form .input-group {
    z-index: 1;
}

.new-header .searcher .search-suggestions .remove-suggestion {
    color: rgb(33,37,41) !important;
    font-weight: bold;
}

.new-header .searcher .search-suggestions .text-suggestion {
    color: rgb(105, 105, 105) !important;
}

.new-header .searcher .search-suggestions .text-suggestion, .new-header .searcher .search-suggestions .text-suggestion * {
    font-size: 13px;
}

.new-header .searcher .remove-search-text {
    background-color: #FFF;
    padding-right: 0;
    padding: 0;
    border: 1px solid #9D9D9D !important;
    border-right: none !important;
    border-left: none !important;
}

.new-header .searcher .remove-search-text span{
    border-right: 1px solid;
    padding: 10px;
    font-size: 15px !important;
    color: black;
}

.new-header .searcher .remove-suggestion {
    font-size: 12px !important;
}

.new-header #country-selector>.row {
    padding: 0 10px;
}

.new-header #country-selector>.row>.col-xs-6 {
    padding: 0 4px;
    margin-top: 8px;
}

.new-header .selector-website {
    padding: 15px;
    border: 1px solid #dee2e6;
    border-radius: .3rem!important;
    display: block;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

@media (min-width: 1400px) {
    .dh-left {
        width: 16.66666667%;
    }
}


.newsletter-form .wrapper * {
    box-sizing: border-box;
}

.newsletter-form .wrapper {
    max-width: 100%;
    position: relative;
}

.newsletter-form .wrapper input {
    background: rgba(255, 255, 255, 0.1);
    border: 2px solid #f1f1f1;
    border-radius: 50px;
    padding: 1rem 2rem;
    width: 100%;
    height: 60px;
}

.newsletter-form .wrapper button {
    background: #F5BF1A;
    border-radius: 50px;
    border: none;
    aspect-ratio: 1;
    height: 75%;
    box-shadow: 1px 1px 6px #9C9C9C;
    font-weight: bold;
    color: #FFFFFF;
    position: absolute;
    top: 13%;
    right: 10px;
    padding: 10px 30px;
    white-space: nowrap;
}
.newsletter-form .form-check-input {
    width: 1em;
    height: 1em;
    margin-top: 0.25em;
    vertical-align: top;
    background-color: #fff;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    border: 1px solid rgba(0,0,0,.25);
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
}
.newsletter-form .form-check-input[type=checkbox] {
    border-radius: 0.25em;
}
.newsletter-form .form-check .form-check-input {
    float: left;
    margin-left: -1.5em;
}
.newsletter-form .radio-big {
    font-size: 20px;
    border-width: 2px;
}
.newsletter-form .form-check {
    margin-bottom: 11px;
}
.newsletter-form .form-check-label {
    color: #9D9D9D !important;
    cursor: pointer;
    font-weight: normal;
    font-size: 15px;
    padding-top: 1px;
}
.newsletter-form .form-check-label a {
    color: #0d6efd;
    text-decoration: underline;
}

.newsletter-form .form-check-input:checked {
    background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'><path fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/></svg>");
}

.newsletter-form .form-check-input:checked {
    background-color: #0d6efd;
    border-color: #0d6efd;
}

.newsletter-form .legal-notice {
    color: #9D9D9D !important;
    font-weight: normal;
    font-size: 10px;
    margin-left: -13px;
    padding-top: 6px;
    clear: both;
}
.newsletter-form .wrapper-top {
    margin-bottom: 34px
}
.newsletter-home {
    padding: 30px !important;
}
.newsletter-form .save-newsletter {
    padding-top: 35px;
}
.newsletter-modal .modal-dialog-centered {
    left: 48% !important;
}
@media (max-width: 768px) {
    .newsletter-form .wrapper button, .newsletter-form .wrapper input {
        font-size: 12px !important;
    }
    .newsletter-form .save-newsletter {
        padding-top: 15px;
    }
    .newsletter-home {
        padding: 15px !important;
    }
    .newsletter-form .wrapper-top {
        margin-bottom: 15px
    }
    .newsletter-home {
        padding-bottom: 0;
    }
    .newsletter-form .form-group {
        margin-bottom: 0;
        padding-left: 8%;
        padding-right: 3%;
    }
    .newsletter-form .form-check {
        margin-bottom: 6px;
    }
    .newsletter-form .form-check-label {
        font-size: 12px;
    }
    .newsletter-form .legal-notice {
        font-size: 8px;
        padding-top: 0;
    }
}